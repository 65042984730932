<template>
  <v-row>
    <v-col cols="12">
      <p>
        今事業年度のFL比率実績を表示しています。
        FL比率とは、売上に対する、Food（食材費）、Labor(人件費）の割合です。
      </p>
      <p class="mb-0">
        ＜グラフの見方＞
        <br />
        本日までの事業年度内の累計売上額を100%として、以下の要素を表しています。
      </p>
      <ul class="ml-4 mb-4">
        <li>緑 = 食材費</li>
        <li>オレンジ = 人件費</li>
        <li>グレー = その他（その他経費と利益の合計）</li>
      </ul>
      <p>FL比率（緑とオレンジ部分）は一般的に60%以内が理想とされています。</p>
      <p>
        ※
        freeeと本アプリを連携している方はCP事務局で勘定科目を食材費・人件費の仕分け設定を行います。
      </p>
      <p>
        事業年度は会計データと連動しています。事業年度が適切に表示されていない場合には
        <router-link to="/contact">お問い合わせページ</router-link>
        よりCP事務局までお問い合わせ下さい。
      </p>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "FLRatioCumulativeTermModal"
};
</script>

<style scoped></style>
